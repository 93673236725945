<style>
    .text {
        font-size: 2.4rem;
        color: var(--color-text-primary);
    }

    @media (max-width: 980px) {
        .text {
            font-size: 1.6rem;
        }
    }
</style>

<p class="text"><slot /></p>