<style>
    .footer {
        display: flex;
        gap: 12px;
        justify-content: center;
    }
    .footer-text {
        font-size: 1.6rem;
        color: var(--color-text-primary);
    }
    .footer-links {
        display: flex;
        gap: 12px;
    }
    .footer-links-link {
        font-size: 1.6rem;
        font-weight: bold;
        color: var(--color-text-primary);
        text-decoration: none;
    }

    @media (max-width: 980px) {
        .footer {
            gap: 5px;
        }
        .footer-links {
            gap: 5px;
        }
        .footer-text {
            font-size: 1.2rem;
        }
        .footer-links-link {
            font-size: 1.2rem;
        }
    }
</style>

<footer class="footer">
    <small class="footer-text">Get in touch with me:</small>
    <ul class="footer-links">
        <li>
            <a class="footer-links-link" target="_blank" href="https://www.linkedin.com/in/lucasalgus/">LinkedIn</a>
        </li>

        <li>
            <a class="footer-links-link" target="_blank" href="https://github.com/lucasalgus">GitHub</a>
        </li>
        <li>
            <a class="footer-links-link" target="_blank" href="https://twitter.com/lucasalgus">Twitter</a>
        </li>
        <li>
            <a class="footer-links-link" target="_blank" href="https://www.instagram.com/lucasalguss/">Instagram</a>
        </li>
    </ul>
</footer>